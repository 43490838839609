import React from "react"

import styles from "./styles.module.scss"

const JamieTweet = () => (
  <div className={`${styles.gifContainer} ${styles.algoliaSearchTweet}`}>
    <video
      id="search-demo-video"
      muted
      preload="auto"
      loop
      playsInline
      autoPlay
      width="100%"
    >
      <source
        src="/web/videos/search-demo.mp4"
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
    <p>
      ⚡️⚡️⚡️⚡️⚡️ Search on{" "}
      <a href="https://developer.kayako.com" target="_blank">
        developer.kayako.com
      </a>
      , powered by <a href="https://twitter.com/algolia">@algolia</a> and built
      by Kintern <a href="https://twitter.com/uberakshay">@uberakshay</a>{" "}
      <a href="https://t.co/KOQj9iF3WU">pic.twitter.com/KOQj9iF3WU</a>
    </p>
    &mdash; Jamie Edwards (@jmedwards){" "}
    <a href="https://twitter.com/jmedwards/status/758972350985441280">
      July 29, 2016
    </a>
  </div>
)

export default JamieTweet
